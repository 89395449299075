import React, { useState } from 'react';
import Slider from 'react-slick';
import { ListingCard, NamedLink } from '../../components';
import { useIntl } from '../../util/reactIntl';
import { cardRenderSizes } from '../SearchPage/SearchResultsPanel/SearchResultsPanel';

import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';
import css from './ListingPage.module.css';

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 2000,
  speed: 500,
  slidesToShow: 4, // Display 4 items on desktop
  slidesToScroll: 1, // Scroll 1 item at a time
  variableWidth: false,
  focusOnSelect: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 3,
        variableWidth: false,
      },
    },
    {
      breakpoint: 768, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2,
        variableWidth: false,
      },
    },
    {
      breakpoint: 580, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1.4,
        variableWidth: false,
      },
    },
    {
      breakpoint: 480, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1.2,
        variableWidth: false,
      },
    },
  ],
};

function SimilarProducts(props) {
  const { listings } = props;

  const intl = useIntl();

  const title = intl.formatMessage({ id: 'SimilarProducts.title' });
  const viewAllProducts = intl.formatMessage({ id: 'SimilarProducts.viewAllProducts' });

  return (
    <div className={css.popularMoorage}>
      <div className={css.sectionHead}>
        <div className={css.leftSec}>
          <h2 className={css.sectionHeadingWithExtraMargin}>{title}</h2>
        </div>
        <div className={css.rightSec}>
          <NamedLink name="SearchPage">{viewAllProducts}</NamedLink>
        </div>
      </div>
      <Slider {...sliderSettings}>
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(true)}
            setActiveListing={() => {}}
          />
        ))}
      </Slider>
    </div>
  );
}

export default SimilarProducts;
